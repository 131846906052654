<template>
  <div>
    <!-- for desktop table -->
    <div class="table__row forDesktop">
      <div class="table__row--cell" data-width="12">
        {{ data.method.type }}
      </div>
      <div class="table__row--cell">
        {{ data.state }}
      </div>
      <div
        class="table__row--cell amount"
        data-width="12"
        :class="data.amount < 0 ? 'negativeValue' : ''"
      >
        <span v-html="formatPrice(data.amount)"></span>
      </div>
    </div>

    <!-- for responsive table -->
    <div class="table__row forResponsive">
      <div class="table__row--cell">
        <p>{{ numberToDate(data.method.type) }}</p>

        {{ data.state }}
      </div>
      <div
        class="table__row--cell"
        data-width="15"
        :class="data.amount < 0 ? 'negativeValue' : ''"
      >
        <span v-html="formatPrice(data.amount)"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: [Array, Object],
  },
};
</script>
<style lang="scss" scoped>
.table {
  color: var(--primary);
  &__row {
    &--cell {
      text-transform: capitalize;
      &.amount {
        color: #24d694 !important;
        ::v-deep {
          i {
            font-style: normal;
            font-size: rem(13px);
          }
        }
      }
      &.negativeValue {
        color: red !important;
      }
    }
  }
}
.forDesktop {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.forResponsive {
  @media screen and (min-width: 768px) {
    display: none;
  }
  p {
    margin-bottom: rem(10px);
    line-height: 1;
    font-size: rem(16px);
    color: var(--textPrimary);
    font-weight: 400;
  }
}
</style>
